import api from "../server";

export default {
  ReadRows(id, key) {
    return api.get(`/${id}/values/Transactions!B5:E?key=${key}`);
  },
  GetExpCategories(id, key) {
    return api.get(
      `/${id}/values/Summary!B28:B?key=${key}&majorDimension=COLUMNS`
    );
  },
  GetIncCategories(id, key) {
    return api.get(
      `/${id}/values/Summary!H28:H?key=${key}&majorDimension=COLUMNS`
    );
  },
  InsertExpenses(id, key, data) {
    return api.put(
      `/${id}/values/Transactions!B4:E4:append?valueInputOption=USER_ENTERED&key=${key}&majorDimension=ROWS`,
      data
    );
  },
};
